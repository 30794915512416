@font-face {
  font-family: 'Poppins Bold';
  src: url('../src/assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins Semi-Bold';
  src: url('../src/assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('../src/assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('../src/assets/fonts/Poppins-Medium.ttf');
}


body {
  margin: 0;
  height: 100%;
  font-family: 'Times';
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dark {
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Replace list-style with transparent img to keep accessibility, remove default padding */
ul,
ol {
  /* list-style-image: url('./assets/icons/transparent-dot.svg'); */
  padding: 0;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Set text-decoration for links to appear on focus and hover */
a {
  text-decoration: none;
}

a:focus,
a:hover {
  text-decoration: underline;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.max-w-screen-p-incl {
  max-width: calc(100vw - 4rem);
}

@media only screen and (min-width: 768px) {
  .max-w-screen-p-incl {
    max-width: calc(100vw - 5rem);
  }
}

.break-word {
  word-break: break-word;
}

/* Styles specific to OpenExo */

h1 {
  font-family: "Poppins Semi-Bold";
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

h2 {
  font-family: "Poppins Semi-Bold";
  font-size: 1.5rem;
  font-weight: 600;

}

h3{
  font-family: "Poppins Regular";
  font-size: 1rem;
  font-weight: 600;
}

h4 {
  font-family: "Poppins Regular";
  font-size: 1rem;
  font-weight: 400;
}

h5 {
  font-family: "Poppins Bold";
  font-size: 0.875rem;
  font-weight: 700;
}

h6 {
  font-family: "Poppins Regular";
  font-size: 0.813;
  font-weight: 400;
}

h7 {
  font-family: "Poppins Bold";
  font-size: 0.813;
  font-weight: 700;
}

p,
ul,
ol,
li,
a,
div,
button { 
  font-family: "Poppins Regular";
  font-size: 0.875rem;
  font-weight: 400;
}



li {
  list-style: none;;
}